import React, { useContext, useEffect, useState } from 'react';
import {
  ButtonRounded,
  ConfirmationStep,
  FormCol,
  FormInput,
  FormRow,
  P,
  PaymentDescription,
  ProcessedByAdvice,
} from './styles';
import { NavPills } from './NavPills';
/* import { ProductCard } from './ProductCard';
 */
import { useNavigate } from 'react-router-dom';
import useColors from '../../hooks/useColors';
import CheckoutContext from '../../contexts/CheckoutContext';
import { numberToReal } from '../../utils/helpers';
import { finishCart } from '../../services/Cart';
import SiteConfigContext from '../../contexts/SiteConfigContext';
import LoadingSpinner from '../Loading/LoadingSpinner';

import mercadopagopng from '../../resources/img/mercadopago.png';
import { toast } from 'react-toastify';
import { NotesModal } from './NotesModal';

export const ConfirmStep = ({ maxProgress, progress, setProgress, setOrderData, formsState }) => {
  const navigate = useNavigate();
  const { primaryColor, auxColor, isDarkTheme } = useColors();
  const { siteConfig } = useContext(SiteConfigContext);
  const { checkoutProducts, checkoutNote, clearCheckout } = useContext(CheckoutContext);
  const [showLoading, setShowLoading] = useState(false);
  const [paymentLink, setPaymentLink] = useState(null);
  const [showCartNotesModal, setShowCartNotesModal] = useState(false);

  const checkoutSubtotal = checkoutProducts.reduce(
    (acc, product) => acc + product.price * product.quantity,
    0
  );
  const deliveryValue = formsState.deliveryType ? parseFloat(formsState.deliveryType.value) : 0;
  const total = checkoutSubtotal + deliveryValue;

  const handleFinishCart = async () => {
    setShowLoading(true);
    const { success, data, error } = await finishCart(
      siteConfig?.store?.id,
      checkoutProducts,
      formsState.address,
      formsState.contact,
      formsState.payment,
      checkoutNote,
      formsState.orderType,
      formsState.deliveryType
    );
    if (!success) {
      toast.error('Erro ao processar a sua compra. Confira os dados e tente novamente.');
    } else {
      clearCheckout();
      setOrderData(data);
      if (formsState.payment === 'mercadopago' || data.payment_link) {
        setPaymentLink(data.urlShortened.url_original);
      } else {
        setProgress(6);
      }
    }
    setShowLoading(false);
  };

  return (
    <ConfirmationStep primaryColor={primaryColor} auxColor={auxColor}>
      <NavPills
        maxProgress={maxProgress}
        progress={progress}
        setProgress={setProgress}
        formsState={formsState}
      />
      {!paymentLink && !showLoading && (
        <div>
          <div className="confirmation-columns">
            <div>
              <ul className="list-products">
                <li>
                  <p>
                    {checkoutProducts.length} {checkoutProducts.length > 1 ? 'itens' : 'item'}
                  </p>
                </li>
                {checkoutProducts
                  .sort((fproduct, gproduct) => fproduct.id > gproduct.id)
                  .map((product) => (
                    <li key={product.id + product.quantity}>
                      <div className="product-card">
                        <div style={{ position: 'relative' }}>
                          {product.image ? (
                            <img
                              className="product-image"
                              src={product.image}
                              alt="Imagem do produto"
                            />
                          ) : (
                            <div className="product-image">
                              <i className="fa-solid fa-camera"></i>
                            </div>
                          )}
                          <span>{product.quantity}</span>
                        </div>

                        <P>{product.name}</P>

                        <P>{numberToReal((product.price * product.quantity).toFixed(2))}</P>
                      </div>
                    </li>
                  ))}
              </ul>
              {formsState.orderType === 'delivery' && formsState.deliveryType && (
                <>
                  <P
                    style={{
                      fontSize: '0.9rem',
                      textAlign: 'right',
                      marginTop: '10px',
                      marginBottom: '5px',
                    }}
                  >
                    <strong>Subtotal: {numberToReal(checkoutSubtotal.toFixed(2))}</strong>
                  </P>
                  <P
                    style={{
                      fontSize: '0.9rem',
                      textAlign: 'right',
                      marginTop: '5px',
                      marginBottom: '10px',
                    }}
                  >
                    Entrega ({formsState.deliveryType?.name}):{' '}
                    {numberToReal(parseFloat(formsState.deliveryType?.value).toFixed(2))}
                  </P>
                </>
              )}
              <P
                style={{
                  fontSize: '1.1rem',
                  textAlign: 'right',
                  marginTop: '5px',
                  marginBottom: '10px',
                }}
              >
                <strong>Total: {numberToReal(total.toFixed(2))}</strong>
              </P>
            </div>
            <div className="confirmation-sections">
              <div>
                <h1>
                  <span>Método de Pagamento</span>
                  <button className="edit-button" onClick={() => setProgress(2)}>
                    <img
                      src="https://api.iconify.design/material-symbols:edit-outline.svg"
                      alt="Icone de Edição"
                      width="20"
                    />
                    Editar
                  </button>
                </h1>
                <p>
                  {formsState.payment?.paymentMethod.slug === 'mercadopago' && (
                    <>
                      Pagamento Online
                      <ProcessedByAdvice>
                        Processado por <br />
                        <img
                          src={mercadopagopng}
                          alt="Icone mercado pago"
                          style={{ width: '3.5rem', marginLeft: '0.5rem' }}
                        />
                      </ProcessedByAdvice>
                    </>
                  )}
                  {formsState.payment?.paymentMethod.slug !== 'mercadopago' && (
                    <>
                      <span>{formsState.payment?.paymentMethod.name}</span>
                      {formsState.payment?.paymentMethod.description && (
                        <PaymentDescription>
                          {formsState.payment?.paymentMethod.description}
                        </PaymentDescription>
                      )}
                    </>
                  )}
                </p>
              </div>
              <div>
                <h1>
                  <span>Dados do Cliente</span>
                  <button className="edit-button" onClick={() => setProgress(3)}>
                    <img
                      src="https://api.iconify.design/material-symbols:edit-outline.svg"
                      alt="Icone de Edição"
                      width="20"
                    />
                    Editar
                  </button>
                </h1>
                <form>
                  <FormRow>
                    <FormCol width="100%">
                      <label htmlFor="customer_name">Nome</label>
                      <FormInput name="customer_name" value={formsState.contact?.name} disabled />
                    </FormCol>
                  </FormRow>
                  <FormRow>
                    <FormCol width="50%">
                      <label htmlFor="customer_email">Email</label>
                      <FormInput name="customer_email" value={formsState.contact?.email} disabled />
                    </FormCol>
                    <FormCol width="50%">
                      <label htmlFor="customer_phone">Telefone</label>
                      <FormInput
                        name="customer_phone"
                        value={formsState.contact?.telephone}
                        disabled
                      />
                    </FormCol>
                  </FormRow>
                </form>
              </div>
              {formsState.orderType === 'pickup' && siteConfig.store?.address && (
                <div>
                  <h1>
                    <span>Endereço de Retirada</span>
                  </h1>
                  <span>
                    {siteConfig.store?.address?.address +
                      ', ' +
                      siteConfig.store?.address?.address_number +
                      ', ' +
                      siteConfig.store?.address?.address_complement +
                      ', ' +
                      siteConfig.store?.address?.city +
                      ' - ' +
                      siteConfig.store?.address?.state}
                  </span>
                </div>
              )}
              {formsState.orderType === 'delivery' && (
                <>
                  {formsState.deliveryType && (
                    <div>
                      <h1>
                        <span>Serviço de Entrega</span>
                        <button className="edit-button" onClick={() => setProgress(4)}>
                          <img
                            src="https://api.iconify.design/material-symbols:edit-outline.svg"
                            alt="Icone de Edição"
                            width="20"
                          />
                          Editar
                        </button>
                      </h1>
                      <span>{formsState.deliveryType?.name}</span>
                    </div>
                  )}
                  <div>
                    <h1>
                      <span>Endereço de Entrega</span>
                      <button className="edit-button" onClick={() => setProgress(4)}>
                        <img
                          src="https://api.iconify.design/material-symbols:edit-outline.svg"
                          alt="Icone de Edição"
                          width="20"
                        />
                        Editar
                      </button>
                    </h1>
                    <form>
                      <FormRow>
                        <FormCol width="75%">
                          <label htmlFor="address_street">Rua</label>
                          <FormInput
                            name="address_street"
                            value={formsState.address?.street}
                            disabled
                          />
                        </FormCol>
                        <FormCol width="25%">
                          <label htmlFor="address_number">N°</label>
                          <FormInput
                            name="address_number"
                            value={formsState.address?.number}
                            disabled
                          />
                        </FormCol>
                      </FormRow>
                      <FormRow>
                        <FormCol width="75%">
                          <label htmlFor="address_neighborhood">Bairro</label>
                          <FormInput
                            name="address_neighborhood"
                            value={formsState.address?.neighborhood}
                            disabled
                          />
                        </FormCol>
                        <FormCol width="25%">
                          <label htmlFor="zip_code">CEP</label>
                          <FormInput
                            name="zip_code"
                            value={formsState.address?.zip_code}
                            disabled
                          />
                        </FormCol>
                      </FormRow>
                      <FormRow>
                        <FormCol width="50%">
                          <label htmlFor="city">Cidade</label>
                          <FormInput name="city" value={formsState.address?.city} disabled />
                        </FormCol>
                        <FormCol width="50%">
                          <label htmlFor="uf">Estado</label>
                          <FormInput name="uf" value={formsState.address?.uf} disabled />
                        </FormCol>
                      </FormRow>
                      <FormRow>
                        <FormCol width="100%">
                          <label htmlFor="address_street">Complemento</label>
                          <FormInput
                            name="address_street"
                            value={formsState.address?.complement}
                            disabled
                          />
                        </FormCol>
                      </FormRow>
                    </form>
                  </div>
                </>
              )}
              <div>
                <h1>
                  <span>Observação</span>
                  <button className="edit-button" onClick={() => setShowCartNotesModal(true)}>
                    <img
                      src="https://api.iconify.design/material-symbols:edit-outline.svg"
                      alt="Icone de Edição"
                      width="20"
                    />
                    Editar
                  </button>
                </h1>
                <FormRow>
                  <span style={{ wordWrap: 'break-word', display: 'block' }}>{checkoutNote}</span>
                </FormRow>
              </div>
            </div>
          </div>
          <FormRow style={{ marginTop: 'auto', justifyContent: 'end' }}>
            <FormCol width="25%">
              <ButtonRounded
                onClick={() => navigate('/')}
                auxColor={auxColor}
                primaryColor={primaryColor}
                isDarkTheme={isDarkTheme}
              >
                Cancelar
              </ButtonRounded>
            </FormCol>
            <FormCol width="25%">
              <ButtonRounded
                onClick={handleFinishCart}
                auxColor={auxColor}
                primaryColor={primaryColor}
                isDarkTheme={isDarkTheme}
              >
                Confirmar
              </ButtonRounded>
            </FormCol>
          </FormRow>
        </div>
      )}
      {paymentLink && !showLoading && (
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignSelf: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '10%',
          }}
        >
          <a
            style={{ fontSize: '20px', textDecoration: 'none', fontWeight: 'bold' }}
            href={paymentLink}
            target="_blank"
            rel="noreferrer"
          >
            Clique aqui para efetuar o pagamento
          </a>
        </div>
      )}
      {showLoading && (
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignSelf: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '10%',
          }}
        >
          <LoadingSpinner />
        </div>
      )}
      <NotesModal show={showCartNotesModal} setShow={setShowCartNotesModal} />
    </ConfirmationStep>
  );
};
