import { useState, useContext, useEffect, useRef } from 'react';
import * as z from 'zod';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import useColors from '../../hooks/useColors';
import {
  ProfileCard,
  FormRow,
  FormCol,
  FormTitle,
  FormLabel,
  FormInput,
  FormErrorSpan,
  ProfileContactForm,
  ButtonRounded,
  ProfileContactLoading,
  RegisterLink,
} from './styles';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import LoadingSpinner from '../../components/Loading/LoadingSpinner';
import AuthContext from '../../contexts/AuthContext';
import SiteContext from '../../contexts/SiteConfigContext';
import { processAuthCustomer } from '../../services/Auth';

const schemaLogin = z.object({
  email: z.string().min(1, 'O e-mail é obrigatório!').email('E-mail inválido!'),
  password: z.string().min(1, 'A senha é obrigatória!'),
});

const LoginPage = () => {
  const formRef = useRef(null);
  const navigate = useNavigate();
  const { auxColor, primaryColor, isDarkTheme } = useColors();
  const { authCustomer, setAuthCustomerToken, setAuthCustomer } = useContext(AuthContext);
  const { siteConfig } = useContext(SiteContext);
  const [formsState, setFormState] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [showFormLoading, setShowFormLoading] = useState(false);

  /**
   * Processa cadastro de Customer
   * @param {*} e
   * @returns
   */
  const submitLoginHandler = async (e) => {
    e.preventDefault();
    setShowFormLoading(true);

    const values = Object.fromEntries(new FormData(formRef.current));
    const { success, error } = schemaLogin.safeParse(values);

    if (!success) {
      const errors = error.format();
      setFormErrors({
        email: errors.email && errors.email._errors[0],
        password: errors.password && errors.password._errors[0],
      });
      setShowFormLoading(false);
      return;
    }
    const data = await processAuthCustomer(siteConfig.store.id, values.email, values.password);
    if (data.success) {
      setAuthCustomerToken(data.data.token);
      setAuthCustomer(data.data.customer);
      toast.success('Seja bem-vindo, e boas compras!');
      navigate('/meus-pedidos');
    } else {
      toast.error('Erro ao realizar login. Verifique os dados e tente novamente.');
      setFormErrors({
        email: data.message,
      });
    }
    setShowFormLoading(false);
  };

  const onChangeHandler = (name, value) =>
    setFormState((state) => ({ ...state, contact: { ...state.contact, [name]: value } }));

  const getValueFromState = (name) => (formsState.contact ? formsState.contact[name] : '');

  useEffect(() => {
    if (authCustomer) {
      navigate('/');
    }
  }, [authCustomer, navigate]);

  return (
    <>
      <Header />
      <ProfileCard>
        <ProfileContactForm ref={formRef}>
          {!showFormLoading && (
            <>
              <FormTitle primaryColor={primaryColor}>Efetue seu Login</FormTitle>
              <FormRow>
                <FormCol width="100%">
                  <FormLabel htmlFor="email">E-mail</FormLabel>
                  <FormInput
                    required
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Informe seu e-mail de acesso"
                    value={getValueFromState('email')}
                    onChange={(e) => onChangeHandler('email', e.target.value)}
                    hasError={!!formErrors.email}
                  />
                  {formErrors.email && <FormErrorSpan>{formErrors.email}</FormErrorSpan>}
                </FormCol>
              </FormRow>

              <FormRow>
                <FormCol width="100%">
                  <FormLabel htmlFor="email">Senha</FormLabel>
                  <FormInput
                    required
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Informe sua senha de acesso"
                    value={getValueFromState('password')}
                    onChange={(e) => onChangeHandler('password', e.target.value)}
                    hasError={!!formErrors.password}
                  />
                  {formErrors.password && <FormErrorSpan>{formErrors.password}</FormErrorSpan>}
                </FormCol>
              </FormRow>
              <FormRow>
                <FormCol width="100%">
                  <ButtonRounded
                    onClick={submitLoginHandler}
                    auxColor={auxColor}
                    primaryColor={primaryColor}
                    isDarkTheme={isDarkTheme}
                  >
                    Entrar
                  </ButtonRounded>
                </FormCol>
              </FormRow>
              <FormRow>
                <FormCol width="100%">
                  <RegisterLink primaryColor={primaryColor} to="/cadastre-se">
                    Não possui registro? Cadastre-se
                  </RegisterLink>
                </FormCol>
              </FormRow>
            </>
          )}
          {showFormLoading && (
            <ProfileContactLoading>
              <LoadingSpinner color={isDarkTheme ? primaryColor : auxColor} />
            </ProfileContactLoading>
          )}
        </ProfileContactForm>
      </ProfileCard>
      <Footer />
    </>
  );
};

export default LoginPage;
