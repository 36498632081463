import { useContext } from 'react';
import { TagsNavVerticalWrapper } from './styles';
import ProductsSearchContext from '../../contexts/ProductsSearchContext';

const VerticalNav = () => {
  const { categories, categoryFilter, setCategoryFilter } = useContext(ProductsSearchContext);

  const activeIndex = categories.indexOf(categoryFilter);

  return (
    <TagsNavVerticalWrapper>
      <p
        className="active"
        style={{
          position: 'absolute',
          bottom: '0',
          left: `${activeIndex * 7.5 + 0.75}rem`,
          transition: 'all 0.4s ease',
        }}
      ></p>
      {categories?.map((category) => (
        <p key={category} onClick={() => setCategoryFilter(category)}>
          {category}
        </p>
      ))}
    </TagsNavVerticalWrapper>
  );
};

export default VerticalNav;
