import styled from 'styled-components';

export const TitleWrapper = styled.div`
  background-color: #f8f8f8;
  color: #787878;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2.5rem;

  padding: 3rem 10% 2rem;

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    gap: 1rem;
  }

  div h1 {
    color: ${({ primaryColor, auxColor, isDarkTheme }) => (isDarkTheme ? primaryColor : auxColor)};
    font-size: 1.5rem;
    font-weight: 700;
  }

  div div {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  @media (max-width: 768px) {
    div {
      flex-direction: column;
    }

    div h1 {
      max-width: none;
      text-align: center;
    }
  }
`;

export const FiltersWrapper = styled.div`
  position: relative;
  padding: 3rem 10% 1rem;
  display: flex;
  justify-content: end;
  gap: 1rem;

  @media (max-width: 768px) {
    padding: 1rem 10% 0;
    width: 100%;
  }
`;

export const CategoriesSelect = styled.select`
  background-color: #fff;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSJncmF5IiBkPSJNNyA2aDEwbC01LjAxIDYuM0w3IDZ6bS0yLjc1LS4zOUM2LjI3IDguMiAxMCAxMyAxMCAxM3Y2YzAgLjU1LjQ1IDEgMSAxaDJjLjU1IDAgMS0uNDUgMS0xdi02czMuNzItNC44IDUuNzQtNy4zOUEuOTk4Ljk5OCAwIDAgMCAxOC45NSA0SDUuMDRjLS44MyAwLTEuMy45NS0uNzkgMS42MXoiLz48L3N2Zz4=);
  background-repeat: no-repeat;
  background-position: right 0.45rem center;
  background-size: 24px;
  appearance: none;
  cursor: pointer;

  border: 1px solid #ced4da;
  border-radius: 0.4rem;

  padding: 0.5rem;

  color: #787878;
  font-weight: 400;
  font-size: 0.9rem;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;

  height: 2.8rem;
  width: 2.5rem;
  transition: all 0.7s ease-out;
  color: transparent;

  max-width: 30%;

  @media (max-width: 768px) {
    max-width: 100%;
  }

  &:focus-visible {
    outline: none;
  }

  &::placeholder {
    color: transparent;
  }

  &:hover,
  &:focus {
    width: 100%;
    color: black;

    &::placeholder {
      color: unset;
    }
  }

  @media (max-width: 576px) {
    display: none;
  }
`;

export const OrderBySelect = styled.select`
  background-color: #fff;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxZW0iIGhlaWdodD0iMWVtIiB2aWV3Qm94PSIwIDAgMjQgMjQiPjxwYXRoIGZpbGw9ImdyYXkiIGQ9Ik0xMSAxOGgyYy41NSAwIDEtLjQ1IDEtMXMtLjQ1LTEtMS0xaC0yYy0uNTUgMC0xIC40NS0xIDFzLjQ1IDEgMSAxek0zIDdjMCAuNTUuNDUgMSAxIDFoMTZjLjU1IDAgMS0uNDUgMS0xcy0uNDUtMS0xLTFINGMtLjU1IDAtMSAuNDUtMSAxem00IDZoMTBjLjU1IDAgMS0uNDUgMS0xcy0uNDUtMS0xLTFIN2MtLjU1IDAtMSAuNDUtMSAxcy40NSAxIDEgMXoiLz48L3N2Zz4=);
  background-repeat: no-repeat;
  background-position: right 0.45rem center;
  background-size: 24px;
  appearance: none;
  cursor: pointer;

  border: 1px solid #ced4da;
  border-radius: 0.4rem;

  padding: 0.5rem;

  color: #787878;
  font-weight: 400;
  font-size: 0.9rem;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;

  height: 2.8rem;
  width: 2.5rem;
  transition: all 0.7s ease-out;
  color: transparent;
  max-width: 30%;

  @media (max-width: 768px) {
    max-width: 100%;
  }

  &:focus-visible {
    outline: none;
  }

  &::placeholder {
    color: transparent;
  }

  &:hover,
  &:focus {
    width: 100%;
    color: black;

    &::placeholder {
      color: unset;
    }
  }
`;

export const SearchInput = styled.input`
  border: 1px solid #ced4da;
  border-radius: 0.4rem;

  padding-left: 1rem;
  margin: auto 0;

  color: #787878;
  font-size: 0.9rem;
  font-family: 'Poppins', sans-serif;

  /* icon at the end of the input */
  background-color: #fff;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSJncmF5IiBkPSJNMTUuNSAxNGgtLjc5bC0uMjgtLjI3QTYuNDcxIDYuNDcxIDAgMCAwIDE2IDkuNUE2LjUgNi41IDAgMSAwIDkuNSAxNmMxLjYxIDAgMy4wOS0uNTkgNC4yMy0xLjU3bC4yNy4yOHYuNzlsNSA0Ljk5TDIwLjQ5IDE5bC00Ljk5LTV6bS02IDBDNy4wMSAxNCA1IDExLjk5IDUgOS41UzcuMDEgNSA5LjUgNVMxNCA3LjAxIDE0IDkuNVMxMS45OSAxNCA5LjUgMTR6Ii8+PC9zdmc+);
  background-position: right 0.3rem center;
  background-size: 26px;
  background-repeat: no-repeat;

  &:focus-visible {
    outline: none;
  }

  height: 2.8rem;
  width: 2.5rem;
  transition: all 0.7s ease-out;
  color: transparent;
  max-width: 30%;

  @media (max-width: 768px) {
    max-width: 100%;
  }

  &::-webkit-search-cancel-button {
    appearance: none;
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxZW0iIGhlaWdodD0iMWVtIiB2aWV3Qm94PSIwIDAgMjQgMjQiPjxwYXRoIGZpbGw9ImdyYXkiIGQ9Ik0xOSA2LjQxTDE3LjU5IDVMMTIgMTAuNTlMNi40MSA1TDUgNi40MUwxMC41OSAxMkw1IDE3LjU5TDYuNDEgMTlMMTIgMTMuNDFMMTcuNTkgMTlMMTkgMTcuNTlMMTMuNDEgMTJ6Ii8+PC9zdmc+)
      no-repeat 50% 50%;
    background-size: contain;
    position: relative;
    right: 2rem;
    height: 1.6rem;
    width: 1.6rem;
    cursor: pointer;
  }

  &::placeholder {
    color: transparent;
  }

  &:hover,
  &:focus {
    width: 100%;
    color: black;

    &::placeholder {
      color: unset;
    }
  }
`;

export const TagsNavVerticalWrapper = styled.div`
  position: relative;
  width: 100vw;
  display: flex;
  margin-top: 0.5rem;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: red;
  }

  @media (min-width: 576px) {
    display: none;
  }

  p {
    text-align: center;
    padding: 1rem 1.5rem;
    font-size: 0.85rem;
    color: #555;
    border-bottom: 2px solid gray;
    min-width: 7.5rem;
  }

  p:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .active {
    padding: 0;
    color: black;
    border-bottom: 2px solid black;
    min-width: 6rem;
    height: 1px;
  }

  .active:hover {
    text-decoration: none;
  }
`;
