import styled from 'styled-components';

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;

  ul {
    background-color: ${(props) => (props.isLightTheme ? '#7f7f7f' : props.primaryColor)};
    border: 0.25px solid ${(props) => (props.isLightTheme ? '#7f7f7f' : props.primaryColor)};
    border-radius: 0.375rem;
    overflow: hidden;

    padding: 0;
    display: flex;
    gap: 1px;
    list-style: none;
  }
`;

export const PaginationItem = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 2rem;
  height: 2.3rem;

  color: ${(props) => (props.isLightTheme ? '#202020' : props.primaryColor)}};
  font-family: 'Poppins', sans-serif;

  border-radius: 0;
  border: none;
  margin: 0;

  ${(props) => (props.disabled ? '' : 'cursor: pointer;')}

  background-color: ${(props) => (props.disabled ? '#efefef' : '#ffffff')};

  &:hover {
    background-color: ${(props) => (props.disabled ? '#efefef' : '#f9f9f9')};
  }
`;
