import React, { useContext } from 'react';
import ProductsSearchContext from '../../contexts/ProductsSearchContext';
import SiteContext from '../../contexts/SiteConfigContext';
import useColors from '../../hooks/useColors';
import Pagination from '../Pagination';
import ProductListItem from '../ProductsListItem';
import { P, ProductListWrapper, CenteredLoadingSpinner } from './styles';

function ProductList() {
  const { primaryColor } = useColors();
  const { siteConfig } = useContext(SiteContext);
  const { products, loading, lastPage, page, setPage } = useContext(ProductsSearchContext);

  const getProductCards = () => {
    if (loading) return <CenteredLoadingSpinner color={primaryColor} />;

    return (
      <ProductListWrapper>
        {products &&
          products.length > 0 &&
          products.map((p) => <ProductListItem key={p.id} product={p} />)}
      </ProductListWrapper>
    );
  };

  return (
    <>
      {getProductCards()}
      {products && products.length > 0 && lastPage > 1 && (
        <Pagination page={page} setPage={setPage} lastPage={lastPage} />
      )}
      <P>
        Dica de segurança: evite golpes. Não pague por itens antecipadamente se você não conhece o
        vendedor. Todos os itens e ofertas listados são de responsabilidade de{' '}
        {siteConfig?.store?.name}.
      </P>
    </>
  );
}

export default ProductList;
