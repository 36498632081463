import api from '../api';

export async function checkIfCustomerExists(storeId, email) {
  try {
    const { data } = await api.post('store/checkIfExists', { store_id: storeId, email: email });
    return {
      message: 'Dados de customer carregados.',
      success: true,
      data,
    };
  } catch (error) {
    return {
      message: 'Erro ao carregar dados do customer. Tente mais tarde.',
      success: false,
    };
  }
}

export async function checkIfUseMercadoPago(storeId) {
  try {
    const { data } = await api.post('store/use-link', { store_id: storeId });

    return data.success;
  } catch (error) {
    return {
      message: 'Erro ao verificar uso de mercado pago. Tente mais tarde.',
      success: false,
    };
  }
}

export async function processAuthCustomer(storeId, email, password) {
  try {
    const { data } = await api.post('store/auth', {
      store_id: storeId,
      email: email,
      password: password,
    });
    return {
      message: 'Login realizado com sucesso.',
      success: true,
      data,
    };
  } catch (error) {
    return {
      message: 'Erro ao realizar login. Tente mais tarde.',
      success: false,
    };
  }
}

export async function registerCustomer(
  storeId,
  name,
  email,
  telephone,
  password,
  passwordConfirmation
) {
  try {
    const { data } = await api.post('store/register', {
      store_id: storeId,
      email: email,
      name: name,
      telephone: telephone,
      password: password,
      password_confirmation: passwordConfirmation,
    });
    return {
      message: 'Dados de customer carregados.',
      success: true,
      data,
    };
  } catch (error) {
    return {
      message: 'Erro ao carregar dados do customer. Tente mais tarde.',
      success: false,
    };
  }
}

export async function registerQuickBuyCustomer(storeId, name, email, telephone) {
  try {
    const { data } = await api.post('store/register-quick', {
      store_id: storeId,
      email: email,
      name: name,
      telephone: telephone,
    });
    return {
      message: 'Cadastro rápido de cliente realizado com sucesso!',
      success: true,
      data,
    };
  } catch (error) {
    return {
      message: 'Erro no cadastro rápido de cliente. Tente mais tarde.',
      success: false,
    };
  }
}

export async function getCustomer(token) {
  try {
    const { data } = await api.get('store/profile', {
      headers: { Authorization: 'Bearer ' + token },
    });
    return {
      message: 'Dados de cliente resgatados com sucesso!',
      success: true,
      data,
    };
  } catch (error) {
    return {
      message: 'Erro no resgate de dados de cliente. Tente mais tarde.',
      success: false,
    };
  }
}

export async function updateCustomer(
  token,
  name,
  email,
  telephone,
  password = '',
  passwordConfirmation = ''
) {
  try {
    const customerData = {
      email: email,
      name: name,
      telephone: telephone,
    };
    if (password && password !== '') {
      customerData['password'] = password;
      customerData['password_confirmation'] = passwordConfirmation;
    }
    const { data } = await api.put('store/profile', customerData, {
      headers: { Authorization: 'Bearer ' + token },
    });
    return {
      message: 'Dados de customer editados com sucesso!',
      success: true,
      data,
    };
  } catch (error) {
    return {
      message: 'Erro ao editar dados do customer. Tente mais tarde.',
      success: false,
    };
  }
}
