import { useState, useContext, useEffect, useRef } from 'react';
import { useIMask } from 'react-imask';
import * as z from 'zod';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import useColors from '../../hooks/useColors';
import {
  ProfileCard,
  FormRow,
  FormCol,
  FormTitle,
  FormLabel,
  FormInput,
  FormErrorSpan,
  ProfileContactForm,
  ButtonRounded,
  ProfileContactLoading,
} from './styles';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import LoadingSpinner from '../../components/Loading/LoadingSpinner';
import AuthContext from '../../contexts/AuthContext';
import { updateCustomer } from '../../services/Auth';
import CustomerHeader from '../../components/CustomerHeader';

const schemaProfile = z
  .object({
    name: z.string().min(1, 'Nome é obrigatório'),
    telephone: z
      .string()
      .min(1, 'Telefone é obrigatório')
      .refine(
        (value) => /^\([1-9]{2}\) [9]{0,1}[0-9]{4}-[0-9]{4}$/.test(value),
        'Telefone inválido'
      ),
    email: z.string().min(1, 'E-mail é obrigatório!').email('E-mail inválido!'),
    password: z.string(),
    password_confirmation: z.string(),
  })
  .refine((data) => data.password === data.password_confirmation, {
    message: 'Senhas não conferem!',
    path: ['password_confirmation'], // path of error
  });

const ProfilePage = () => {
  const navigate = useNavigate();
  const formRef = useRef(null);
  const { auxColor, primaryColor, isDarkTheme } = useColors();
  const { authCustomer, authCustomerToken } = useContext(AuthContext);
  const [phoneMask, setPhoneMask] = useState('(00) 0000-00000');
  const {
    ref: phoneRef,
    value: phoneValue,
    setValue: setPhoneValue,
  } = useIMask(
    { mask: phoneMask },
    {
      onAccept: (value) => {
        setPhoneMask(value[5] === '9' && value.length >= 14 ? '(00) 00000-0000' : '(00) 0000-0000');
        onChangeHandler('telephone', value);
      },
    }
  );

  const [formsState, setFormState] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [showFormLoading, setShowFormLoading] = useState(true);

  /**
   * Processa cadastro de Customer
   * @param {*} e
   * @returns
   */
  const submitProfileUpdateHandler = async (e) => {
    e.preventDefault();
    setShowFormLoading(true);

    const values = Object.fromEntries(new FormData(formRef.current));
    const { success, error } = schemaProfile.safeParse(values);

    if (!success) {
      const errors = error.format();
      setFormErrors({
        name: errors.name && errors.name._errors[0],
        telephone: errors.telephone && errors.telephone._errors[0],
        email: errors.email && errors.email._errors[0],
        password: errors.password && errors.password._errors[0],
        password_confirmation:
          errors.password_confirmation && errors.password_confirmation._errors[0],
      });
      setShowFormLoading(false);
      return;
    }
    //edita o Customer na base
    const data = await updateCustomer(
      authCustomerToken,
      values.name,
      values.email,
      values.telephone,
      values.password,
      values.password_confirmation
    );
    if (!data.success) {
      //Em caso de erro na edicao do customer
      toast.error('Erro ao editar o seu perfil. Verifique os dados e tente novamente.');
    }
    setShowFormLoading(false);
  };

  const onChangeHandler = (name, value) =>
    setFormState((state) => ({ ...state, contact: { ...state.contact, [name]: value } }));

  const getValueFromState = (name) => (formsState.contact ? formsState.contact[name] : '');

  useEffect(() => {
    if (authCustomer) {
      setFormState((state) => ({
        ...state,
        contact: {
          ...state.contact,
          name: authCustomer.name,
          telephone: authCustomer.telephone,
          email: authCustomer.email,
        },
      }));
      setPhoneValue(authCustomer.telephone);
      setShowFormLoading(false);
    } else {
      navigate('/');
    }
  }, [authCustomer, navigate, setFormState, setPhoneValue]);

  return (
    <>
      <Header />
      <CustomerHeader primaryColor={primaryColor} page="profile" />
      <ProfileCard primaryColor={primaryColor}>
        <ProfileContactForm ref={formRef}>
          <FormTitle>Editar perfil</FormTitle>
          {!showFormLoading && (
            <>
              <FormRow>
                <FormCol width="100%">
                  <FormLabel htmlFor="email">E-mail</FormLabel>
                  <span>{getValueFromState('email')}</span>
                </FormCol>
              </FormRow>
              <FormRow>
                <FormCol width="100%">
                  <FormLabel htmlFor="name">Nome</FormLabel>
                  <FormInput
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Preencha seu nome"
                    value={getValueFromState('name')}
                    onChange={(e) => onChangeHandler('name', e.target.value)}
                  />
                  {formErrors.name && <FormErrorSpan>{formErrors.name}</FormErrorSpan>}
                </FormCol>
              </FormRow>
              <FormRow>
                <FormCol width="100%">
                  <FormLabel htmlFor="telephone">Telefone</FormLabel>
                  <FormInput
                    ref={phoneRef}
                    type="tel"
                    name="telephone"
                    id="telephone"
                    placeholder="Telefone"
                    defaultValue={getValueFromState('telephone')}
                  />
                  {formErrors.telephone && <FormErrorSpan>{formErrors.telephone}</FormErrorSpan>}
                </FormCol>
              </FormRow>
              <FormRow>
                <FormCol width="100%">
                  <FormLabel htmlFor="email">Senha</FormLabel>
                  <FormInput
                    required
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Senha"
                    value={getValueFromState('password')}
                    onChange={(e) => onChangeHandler('password', e.target.value)}
                  />
                  {formErrors.password && <FormErrorSpan>{formErrors.password}</FormErrorSpan>}
                </FormCol>
              </FormRow>
              <FormRow>
                <FormCol width="100%">
                  <FormLabel htmlFor="email">Confirmar Senha</FormLabel>
                  <FormInput
                    required
                    type="password"
                    name="password_confirmation"
                    id="password_confirmation"
                    placeholder="Confirme sua Senha"
                    value={getValueFromState('password_confirmation')}
                    onChange={(e) => onChangeHandler('password_confirmation', e.target.value)}
                  />
                  {formErrors.password_confirmation && (
                    <FormErrorSpan>{formErrors.password_confirmation}</FormErrorSpan>
                  )}
                </FormCol>
              </FormRow>
              <FormRow>
                <FormCol width="100%">
                  <ButtonRounded
                    onClick={submitProfileUpdateHandler}
                    auxColor={auxColor}
                    primaryColor={primaryColor}
                    isDarkTheme={isDarkTheme}
                  >
                    Salvar
                  </ButtonRounded>
                </FormCol>
              </FormRow>
            </>
          )}
          {showFormLoading && (
            <ProfileContactLoading>
              <LoadingSpinner />
            </ProfileContactLoading>
          )}
        </ProfileContactForm>
      </ProfileCard>
      <Footer />
    </>
  );
};

export default ProfilePage;
