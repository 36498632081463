import React, { useEffect, createContext, useState, useCallback, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { productsList } from '../services/Products';
import SiteConfigContext from './SiteConfigContext';

export const ProductsSearchContext = createContext({});

export const ProductsSearchProvider = ({ children }) => {
  const { subdomain, SiteConfig } = useContext(SiteConfigContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [categoryFilter, setCategoryFilter] = useState('');
  const [categories, setCategories] = useState([]);
  const [query, setQuery] = useState(searchParams.get('query') || '');
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [orderby, setOrderby] = useState(searchParams.get('orderby') || null);
  const [page, setPage] = useState(searchParams.get('page') || 1);

  const listProducts = useCallback(async () => {
    setLoading(true);

    const productsListing = [];
    let loopLastPage = 2;

    for (let loopIndex = 1; loopIndex <= loopLastPage; loopIndex++) {
      const { data, success } = await productsList(subdomain, { page: loopIndex, orderby, query });

      if (!success) {
        toast.error('Erro ao carregar a lista de produtos.');
        setLoading(false);
        return;
      }

      if (SiteConfig?.store?.configs?.products_listing === 3)
        productsListing.push(...data.products.data.filter((product) => product.amount > 0));
      else productsListing.push(...data.products.data);

      loopLastPage = data.products.last_page;
    }

    setCategories([...new Set(productsListing.map((product) => product.category.name))]);

    setProducts(productsListing);
    setLoading(false);
  }, [subdomain, SiteConfig, orderby, query]);

  useEffect(() => {
    const params = {};

    if (query) params.query = query;

    if (orderby) params.orderby = orderby;

    if (page && page !== 1) params.page = page;

    setSearchParams(params);
  }, [searchParams, setSearchParams, query, orderby, page]);

  useEffect(() => {
    listProducts();
  }, [listProducts]);

  return (
    <ProductsSearchContext.Provider
      value={{
        loading,
        setQuery,
        query,
        categories,
        setCategoryFilter,
        categoryFilter,
        lastPage:
          Math.floor(
            (products.filter(
              (product) => categoryFilter === '' || product.category?.name === categoryFilter
            ).length -
              1) /
              16
          ) + 1,
        products: products
          .filter((product) => categoryFilter === '' || product.category?.name === categoryFilter)
          .slice(16 * (page - 1), 16 * page),
        page,
        setPage,
        setOrderby,
        orderby,
      }}
    >
      {children}
    </ProductsSearchContext.Provider>
  );
};

export default ProductsSearchContext;
