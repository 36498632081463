import React, { useContext } from 'react';
import Header from '../../components/Header';
import Banner from '../../components/Banner';
import ProductsList from '../../components/ProductsList';
import Footer from '../../components/Footer';
import SiteContext from '../../contexts/SiteConfigContext';
import useColors from '../../hooks/useColors';
import { TitleWrapper, OrderBySelect, FiltersWrapper, CategoriesSelect } from './styles';
import ProductsSearchContext from '../../contexts/ProductsSearchContext';
import SearchInput from './SearchInput';
import VerticalNav from './VerticalNav';

const Home = () => {
  const { siteConfig } = useContext(SiteContext);
  const { products, setOrderby, categories, categoryFilter, setCategoryFilter } =
    useContext(ProductsSearchContext);

  const { primaryColor, isDarkTheme, auxColor } = useColors();

  return (
    <>
      <Header />

      <TitleWrapper primaryColor={primaryColor} auxColor={auxColor} isDarkTheme={isDarkTheme}>
        <div>
          <h1>{siteConfig?.store?.title}</h1>
        </div>
      </TitleWrapper>

      <Banner />
      <FiltersWrapper>
        <CategoriesSelect
          value={categoryFilter}
          onChange={(e) => setCategoryFilter(e.target.value)}
          aria-label="Seleção de filtro de categorias"
        >
          <option value="">Selecione...</option>
          {categories?.map((category) => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </CategoriesSelect>
        {products.length > 1 && (
          <OrderBySelect
            onChange={(e) => setOrderby(e.target.value)}
            aria-label="Seleção de ordenação"
          >
            <option value="">Selecione...</option>
            {/* <option value="mais_vendidos">Mais vendidos</option> */}
            {/* <option value="mais_bem_avaliados">Mais bem avaliados</option> */}
            <option value="created_at_desc">Lançamento</option>
            <option value="sell_price_asc">Menor preço</option>
            <option value="sell_price_desc">Maior preço</option>
          </OrderBySelect>
        )}
        <SearchInput />
      </FiltersWrapper>

      <VerticalNav />
      <ProductsList />
      <Footer />
    </>
  );
};

export default Home;
