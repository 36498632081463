import api from '../api';

export async function productsList(slug, { page, orderby, query }) {
  try {
    let url = 'store/' + slug + '/products?page=' + page;

    if (query !== '' && query !== null) {
      url += '&query=' + query;
    }
    if (orderby !== '' && orderby !== null) {
      url += '&orderby=' + orderby;
    }
    const { data } = await api.get(url, {});

    return {
      message: 'Produtos carregados com sucesso.',
      success: true,
      data,
    };
  } catch (error) {
    return {
      message: 'Erro ao carregar produtos. Tente mais tarde.',
      success: false,
    };
  }
}

export async function productRead(slug, id) {
  try {
    const { data } = await api.get('store/' + slug + '/products/' + id, {});

    return {
      message: 'Produto carregado com sucesso.',
      success: true,
      data,
    };
  } catch (error) {
    return {
      message: 'Erro ao carregar produto. Tente mais tarde.',
      success: false,
    };
  }
}
