import useColors from '../../hooks/useColors';
import { PaginationWrapper, PaginationItem } from './styles';

function Pagination({ page, setPage, lastPage }) {
  const { primaryColor, isLightTheme } = useColors();

  const getPagination = () => {
    const pageList = [];
    for (let i = 1; i <= lastPage; i++) {
      pageList.push(
        <li>
          <PaginationItem
            key={i}
            disabled={page === i}
            primaryColor={primaryColor}
            isLightTheme={isLightTheme}
            onClick={() => page !== i && setPage(i)}
          >
            {i}
          </PaginationItem>
        </li>
      );
    }
    return pageList;
  };

  return (
    <PaginationWrapper primaryColor={primaryColor} isLightTheme={isLightTheme}>
      <nav>
        <ul>
          <li>
            <PaginationItem
              disabled={page === 1}
              primaryColor={primaryColor}
              isLightTheme={isLightTheme}
              onClick={() => setPage(page - 1)}
            >
              &laquo;
            </PaginationItem>
          </li>
          {getPagination()}
          <li>
            <PaginationItem
              disabled={page === lastPage}
              primaryColor={primaryColor}
              isLightTheme={isLightTheme}
              onClick={() => setPage(page + 1)}
            >
              &raquo;
            </PaginationItem>
          </li>
        </ul>
      </nav>
    </PaginationWrapper>
  );
}

export default Pagination;
